.alert p {
  margin-bottom: 0!important;
}
body {
  background: #000428;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #004e92, #000428);  
  background: linear-gradient(to right, #004e92, #000428); 
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
header {
  margin: 30px 0;
}
.contenido {
  padding:20px;
}
.contenido-principal {
  background-color: white;
  padding: 30px;
  border-radius: 20px;
}
.presupuesto p{
  margin-bottom: 0;
  font-weight: bold;
}
.presupuesto p span {
  font-weight: normal;
}
#gastos {
  margin-bottom: 30px;
}
h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 100;
  color: white;
  text-align: center;
  font-size: 4.8rem!important;
}
h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 100;
  color:#004e92;
  margin-bottom:30px;
  font-size:3rem!important;
  text-align: center;
}
.campo {
  margin-bottom: 2rem;
}
.error {
  text-align: center;
}
.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.gastos-realizados  {
  margin-bottom: 2rem;
}
.gastos-realizados li{
  list-style: none;
  margin-bottom: 0rem;
  border-bottom: 1px solid #e1e1e1;
  padding: 1rem 0;
}
.gastos-realizados li:last-of-type {
  margin-bottom: 0!important;
}
.gastos-realizados li p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin:0;
}
.gastos-realizados li p .gasto {
  background-color: #004085;
  padding: 1rem;
  color:white;
}